import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Otherservice from "../components/otherservice";
import { IoArrowUpOutline } from "react-icons/io5";
import { AnimatePresence } from "framer-motion";
import Form from "../components/form";
import MetaTag from "../components/metaTag";
import { variantPages } from "../animations/pageTransition";
import { motion } from "framer-motion";
import { expresscleaningSEO } from "../components/data/seo";
import {
  container,
  preview,
  stock,
  option,
  price,
  list,
  background,
  title,
  description,
  vector,
  otherservices,
  btn,
} from "../styles/pages/service.module.scss";

function Expresscleaning() {
  const [openForm, setOpenForm] = useState(false);

  return (
    <>
      <MetaTag data={expresscleaningSEO} />
      <motion.section
        variants={variantPages}
        initial="initial"
        exit="exit"
        animate="animate"
      >
        <div className={container}>
          <div className={preview}>
            <h2 className={title}>Экспресс уборка</h2>
            <p className={description}>от 2200₽</p>
            <StaticImage
              src="../images/services/2.jpg"
              alt="обложка для Экспресс уборки"
              className={background}
            />
          </div>
          <div className={stock}>
            <h3 className={title}>Закажите клининг по акции!</h3>
            <p>
              При заказе любого типа помещения - скидка до 30% на услуги
              химчистки мягкой мебели
            </p>
            <button className={btn} onClick={() => setOpenForm(true)}>
              Заказать уборку <IoArrowUpOutline />
            </button>
            <img src="/cartvector/2.svg" alt="фон вектор" className={vector} />
          </div>
          <div
            className={option}
            style={{
              background: "linear-gradient(180deg, #4449C5 0%, #FFA764 100%)",
            }}
          >
            <h2 className={title}>Что входит:</h2>
            <p className={description}>
              Дополнительные позиции обсуждаются индивидуально
            </p>
            <p className={list}>
              - Сухая уборка пылесосом (при наличии у клиента)
              <br />
              - Влажная уборка полов и плинтусов, мебели
              <br />
              - Чистка ковров и ковровых покрытий
              <br />
              - Влажная уборка радиаторов и труб
              <br />
              - Влажная уборка подоконников, легкодоступных осветительных
              приборов
              <br />
              - Очистка выключателей и розеток
              <br />
              - Мытье зеркал и стеклянных элементов, окон, рам, жалюзей, дверей
              и дверных проемов
              <br />
              - Мойка холодильника, СВЧ, плиты снаружи
              <br />- Заправка кроватей
            </p>
          </div>
          <div
            className={price}
            style={{
              background: "linear-gradient(64.7deg, #D15842 0%, #F17F29 100%)",
            }}
          >
            <h2 className={title}>Прайс</h2>
            <p className={description}>
              Для расчета более точной цены, оставьте заявку
            </p>
            <p className={list}>
              45 кв.м / от 2200₽
              <br />
              65 кв.м / от 3300₽
              <br />
              85 кв.м / от 4200₽
              <br />
              125 кв.м - 160 кв.м / от 4800₽
              <br />
              более 160 кв.м / индивидуально
            </p>
            <img src="/cartvector/1.svg" alt="фон вектор" className={vector} />
          </div>
        </div>
        <h3 className={otherservices}>Прочие услуги:</h3>
        <Otherservice hide={1} />
        <AnimatePresence>
          {openForm && (
            <Form
              service="экспресс уборку"
              closeForm={() => setOpenForm(false)}
            />
          )}
        </AnimatePresence>
      </motion.section>
    </>
  );
}

export default Expresscleaning;
